// Here you can add other styles

.form-group.required label:after{
    content: "(*)";
    color: red;
}

.react-select-error div {
    border-color: #e74c3c; 
}

.react-select-error:hover div {
    border-color: #e74c3c; 
}